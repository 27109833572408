import React, { useState } from "react";
import NavBar from "../../widgets/NavBar";
import HeaderInfo from "../../widgets/HeaderInfo";
import FooterSection from "../../components/Footer";
import ProductItem from "../../widgets/ProductItem";
import { useNavigate } from "react-router-dom";
import { root } from "../../routes/routeUrls";
import PortFolio from "../../assets/images/portfolio/01.jpg";
import SingleProduct from "../../widgets/SingleProduct";
import { productList } from "./productList";
// import FbarMain from "../../widgets/FbarMain";

export default function Product(): React.ReactElement {
  const navigate = useNavigate();
  const [showProdDetails, setShowProdDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(1);

  const handleToggle = (id: any) => {
    setSelectedProduct(id);
    setShowProdDetails(!showProdDetails);
  };
  return (
    <div className="page">
      <header id="masthead" className="header ttm-header-style-classicinfo">
        {/* not sure what below html does */}
        {/* <FbarMain/> */}
        {/* <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
          <div className="container">
            <div className="ttm-topbar-content">
              <ul className="top-contact text-left">
                <li>
                  <i className="themifyicon ti-location-pin"></i>71/2, 7th 'A'
                  Cross Bazar Street, Rose Garden, Neelasandra, Bengaluru
                </li>
                <li>
                  <i className="themifyicon ti-timer"></i>Mon - Sat 10.00 AM -
                  06.30 PM Sunday CLOSED
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        <NavBar index={2} />
        <div className="ttm-content-wrapper">
          <div className="container">
            <HeaderInfo />
          </div>
        </div>
      </header>
      <div
        className="ttm-page-title-row"
        style={{ backgroundColor: "#033b4a" }}
      >
        <div className="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title">{`${
                    showProdDetails
                      ? "Product Details"
                      : "Products"
                  }`}</h1>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(root)}
                      className="home"
                    >
                      <i className="themifyicon ti-home"></i>
                      &nbsp;&nbsp;Home
                    </span>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                  <span>
                    <span>Products</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        {showProdDetails ? (
          <div className="row multi-columns-row ttm-boxes-row-wrapper pt-15">
            <div className="container">
              <div className="row">
                <div
                  className="breadcrumb-wrapper pointer-cursor"
                  style={{ padding: "12px 0" }}
                  onClick={() => handleToggle(1)}
                >
                  <span>
                    <span className="home" style={{ fontSize: "16px" }}>
                      <i className="ti-control-backward"></i>&nbsp;&nbsp;Back to
                      Products
                    </span>
                  </span>
                </div>
                <SingleProduct
                  product={productList.find(
                    (prod) => prod.id === selectedProduct
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="sidebar ttm-sidebar-right ttm-bgcolor-white clearfix">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 content-area">
                  <p className="products-result-count">Showing results</p>
                  <div className="products row">
                    {productList.map((product) => (
                      <ProductItem
                        product={product}
                        handleToggle={handleToggle}
                      />
                    ))}
                  </div>
                  <br />
                  <br />
                  <br />
                  {/* <div className="col-lg-12">
                    <div className="ttm-pagination text-center">
                      <span
                        aria-current="page"
                        className="page-numbers current"
                      >
                        1
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <FooterSection />
      <a id="totop" href="#top">
        <i className="fa fa-angle-up"></i>
      </a>
    </div>
  );
}
