import React, { useState } from "react";
import NavBar from "../../widgets/NavBar";
import HeaderInfo from "../../widgets/HeaderInfo";
import FooterSection from "../../components/Footer";
import CompanyProfile from "../../assets/images/about_us.jpg";
import Profile from "../../assets/images/chandru-profile.jpg";
import Mission from "../../assets/images/portfolio/our_mission.jpg";
import Vision from "../../assets/images/portfolio/our_vision.jpg";
import Strategies from "../../assets/images/portfolio/our_strategies.jpg";
import { useNavigate } from "react-router-dom";
import { root } from "../../routes/routeUrls";
// import FbarMain from "../../widgets/FbarMain";

export default function About(): React.ReactElement {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");

  const handleSetActiveTab = (name: string) => {
    setActiveTab(name === activeTab ? "" : name);
  };
  return (
    <div className="page">
      <header id="masthead" className="header ttm-header-style-classicinfo">
        {/* not sure what below html does */}
        {/* <FbarMain/> */}
        {/* <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
          <div className="container">
            <div className="ttm-topbar-content">
              <ul className="top-contact text-left">
                <li>
                  <i className="themifyicon ti-location-pin"></i>71/2, 7th 'A'
                  Cross Bazar Street, Rose Garden, Neelasandra, Bengaluru
                </li>
                <li>
                  <i className="themifyicon ti-timer"></i>Mon - Sat 10.00 AM -
                  06.30 PM Sunday CLOSED
                </li>
              </ul>
            </div>
          </div>
        </div> */}

        <NavBar index={1} />

        <div className="ttm-content-wrapper">
          <div className="container">
            <HeaderInfo />
          </div>
        </div>
      </header>
      <div
        className="ttm-page-title-row"
        style={{ backgroundColor: "#033b4a" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title">About Us</h1>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(root)}
                          className="home"
                        >
                          <i className="themifyicon ti-home"></i>
                          &nbsp;&nbsp;Home
                        </span>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                      <span>About Us</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row experiance-section bg-layer break-1199-colum clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-12">
                <div className="ttm-col-bgcolor-yes ttm-bg ttm-left-span pt-95 pb-100 res-991-pt-50 res-991-pb-50">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer ttm-bgcolor-grey"></div>
                  <div className="layer-content">
                    <div className="ttm_single_image-wrapper mr_240 res-1199-mr-0 m-top40">
                      <img className="img-fluid" src={CompanyProfile} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-md-12">
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-9">
                    <div className="pt-105 pb-30 res-1199-plr-15 res-991-pt-50">
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h5>WHAT WE DO</h5>
                          <h2 className="title">About BoneHeal INC</h2>
                        </div>
                        <div className="title-desc text-justify">
                          BoneHeal INC was founded in 2022 with a clear mission
                          in mind - to become a leading player in the
                          specialized fields of medical devices, life sciences,
                          pharmaceuticals, and healthcare consulting &
                          distribution
                        </div>
                      </div>
                      <div className="accordion">
                        <div className="toggle">
                          <div
                            className="toggle-title"
                            onClick={() => handleSetActiveTab("collapseOne")}
                          >
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseOne"
                            >
                              Our Mission
                            </a>
                          </div>
                          <div
                            className={`${
                              activeTab === "collapseOne"
                                ? "show-content"
                                : "toggle-content"
                            }`}
                          >
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="toggle-figure res-991-mb-15">
                                  <img
                                    className="img-fluid"
                                    src={Mission}
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-9 text-justify">
                                <p>
                                  Our primary goal is to provide comprehensive
                                  and expert support to global healthcare and
                                  medical device companies looking to enter the
                                  Indian market. We aim to help them navigate
                                  the complexities of market entry, regulatory
                                  approvals, and team building, ensuring their
                                  success in this dynamic and promising market.
                                  Additionally, we are committed to offering
                                  top-notch healthcare recruitment services to
                                  address the manpower needs of various
                                  healthcare organizations across India, the
                                  United States, and Europe.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="toggle">
                          <div
                            className="toggle-title"
                            onClick={() => handleSetActiveTab("collapseTwo")}
                          >
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwo"
                            >
                              Our Vision
                            </a>
                          </div>
                          <div
                            className={`${
                              activeTab === "collapseTwo"
                                ? "show-content"
                                : "toggle-content"
                            }`}
                          >
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="toggle-figure res-991-mb-15">
                                  <img
                                    className="img-fluid"
                                    src={Vision}
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-9 text-justify">
                                <p>
                                  Our vision at BoneHeal INC is to be recognized
                                  as a prominent and trusted partner for
                                  healthcare and life sciences companies
                                  worldwide. We strive to be the go-to
                                  consultancy for businesses seeking market
                                  expansion into India, offering them a one-stop
                                  solution for all their strategic and
                                  operational needs. We envision ourselves as
                                  leaders in delivering innovative, tailored
                                  and result-oriented solutions that rejuvenate
                                  and optimize our clients' India strategies.
                                  Furthermore, we aim to continue building a
                                  team of experts who bring a wealth of
                                  experience across diverse sectors within the
                                  healthcare and life sciences industry,
                                  positioning us as industry leaders and
                                  pioneers in professional workforce solutions.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="toggle">
                          <div
                            className="toggle-title"
                            onClick={() => handleSetActiveTab("collapseThree")}
                          >
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree"
                            >
                              Our Stratagies
                            </a>
                          </div>
                          <div
                            className={`${
                              activeTab === "collapseThree"
                                ? "show-content"
                                : "toggle-content"
                            }`}
                          >
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="toggle-figure res-991-mb-15">
                                  <img
                                    className="img-fluid"
                                    src={Strategies}
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-9 text-justify">
                                <ul>
                                  <li>
                                    <strong>
                                      Comprehensive Market Entry Support:
                                    </strong>{" "}
                                    We assist global healthcare and medical
                                    device companies entering the Indian market.
                                    Our services include business planning,
                                    market research, regulatory approvals,
                                    partner/distributor search, and team
                                    building.
                                  </li>
                                  <li>
                                    <strong>
                                      Client Strategy Revitalization:
                                    </strong>{" "}
                                    We customize strategies to help clients
                                    overcome challenges like partner selection,
                                    inadequate research, or entry planning
                                    issues for success in the Indian healthcare
                                    market.
                                  </li>
                                  <li>
                                    <strong>Experienced Team:</strong> Our team
                                    comprises seasoned professionals with
                                    expertise in healthcare, operations,
                                    marketing, finance, HR, and market research,
                                    enabling comprehensive client solutions.
                                  </li>
                                  <li>
                                    <strong>
                                      Professional Workforce Solutions:
                                    </strong>{" "}
                                    We cater to the staffing needs of
                                    pharmaceuticals, hospitals, CROs, CDM
                                    companies, pharmacovigilance companies, and
                                    regulatory affairs firms across regions.
                                  </li>
                                  <li>
                                    <strong>Industry Expertise:</strong> We
                                    maintain a deep understanding of the
                                    healthcare and life sciences industry,
                                    keeping our strategies and services aligned
                                    with industry trends and regulations.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row fid-section ttm-bg ttm-bgcolor-skincolor clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-3 co-xs-12">
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <i className="themifyicon ti-user"></i>
                  </div>
                  <div className="ttm-fid-contents">
                    <h4>
                      <span
                        data-appear-animation="animateDigits"
                        data-from="0"
                        data-to="100"
                        data-interval="10"
                        data-before=""
                        data-before-style="sup"
                        data-after=""
                        data-after-style="sub"
                      >
                        100
                      </span>
                    </h4>
                    <h3 className="ttm-fid-title">
                      <span>
                        Clients
                        <br />
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 co-xs-12">
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <i className="themifyicon ti-envelope"></i>
                  </div>
                  <div className="ttm-fid-contents">
                    <h4>
                      <span
                        data-appear-animation="animateDigits"
                        data-from="0"
                        data-to="25"
                        data-interval="5"
                        data-before=""
                        data-before-style="sup"
                        data-after=""
                        data-after-style="sub"
                      >
                        25
                      </span>
                    </h4>
                    <h3 className="ttm-fid-title">
                      <span>
                        Hospitals
                        <br />
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 co-xs-12">
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <i className="themifyicon ti-heart-broken"></i>
                  </div>
                  <div className="ttm-fid-contents">
                    <h4>
                      <span
                        data-appear-animation="animateDigits"
                        data-from="0"
                        data-to="20"
                        data-interval="10"
                        data-before=""
                        data-before-style="sup"
                        data-after=""
                        data-after-style="sub"
                      >
                        20+
                      </span>
                    </h4>
                    <h3 className="ttm-fid-title">
                      <span>
                        Years of Experience
                        <br />
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 co-xs-12">
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <i className="themifyicon ti-thumb-up"></i>
                  </div>
                  <div className="ttm-fid-contents">
                    <h4>
                      <span
                        data-appear-animation="animateDigits"
                        data-from="0"
                        data-to="15"
                        data-interval="10"
                        data-before=""
                        data-before-style="sup"
                        data-after=""
                        data-after-style="sub"
                      >
                        15
                      </span>
                    </h4>
                    <h3 className="ttm-fid-title">
                      <span>
                        Qualified Staffs
                        <br />
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="team-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-12">
                <div className="ttm-col-bgcolor-yes ttm-bg ttm-left-span pt-95 pb-100 res-991-pt-50 res-991-pb-50">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer ttm-bgcolor-grey"></div>
                  <div className="layer-content">
                    <div className="ttm_single_image-wrapper mr_240 res-1199-mr-0 m-top40">
                      <img className="img-fluid-0" src={Profile} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-md-12">
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-9">
                    <div className="pt-105 pb-30 res-1199-plr-15 res-991-pt-50">
                      <div className="section-title with-desc clearfix">
                        <div className="title-header">
                          <h2 className="title">About Chandra Shekara</h2>
                        </div>
                        <div className="title-desc text-justify">
                          An accomplished business leader with 20+ years of
                          expertise in crafting and implementing Go-to-Market
                          strategies on a national scale while assessing their
                          immediate and long-term business impacts.
                        </div>
                      </div>
                      <div className="accordion">
                        <div className="toggle">
                          <div
                            className="toggle-title"
                            onClick={() => handleSetActiveTab("collapseFour")}
                          >
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFour"
                            >
                              Partnership with M/s. Leap Medicare (April 2005 -
                              February 2008)
                            </a>
                          </div>
                          <div
                            className={`${
                              activeTab === "collapseFour"
                                ? "show-content"
                                : "toggle-content"
                            }`}
                          >
                            <div className="row">
                              <div className="col-sm-9 text-justify">
                                <p>
                                  Entered into a partnership with M/s. Leap
                                  Medicare. Served as the exclusive distributor
                                  for South India on behalf of M/s. Biotech
                                  India Pvt Ltd, an Orthopedic Implants
                                  manufacturing company. Distributed a wide
                                  range of products including Inter Locking
                                  Nails, Plates, Screws, and Spine Implants,
                                  covering major cities in South India.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="toggle">
                          <div
                            className="toggle-title"
                            onClick={() => handleSetActiveTab("collapseFive")}
                          >
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFive"
                            >
                              Partnership with M/s. Keerthana Marketings
                              (October 2008 - April 2011)
                            </a>
                          </div>
                          <div
                            className={`${
                              activeTab === "collapseFive"
                                ? "show-content"
                                : "toggle-content"
                            }`}
                          >
                            <div className="row">
                              <div className="col-sm-9 text-justify">
                                <p>
                                  Established a partnership with M/s. Keerthana
                                  Marketings. Served as the Sole Authorized
                                  Distributor for M/s. Biomet Inc, UK. Focused
                                  on Knee, Hip, Elbow, and Shoulder Joints, as
                                  well as bone cements.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="toggle">
                          <div
                            className="toggle-title"
                            onClick={() => handleSetActiveTab("collapseSix")}
                          >
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSix"
                            >
                              Freelancing (2011 - 2019)
                            </a>
                          </div>
                          <div
                            className={`${
                              activeTab === "collapseSix"
                                ? "show-content"
                                : "toggle-content"
                            }`}
                          >
                            <div className="row">
                              <div className="col-sm-9 text-justify">
                                <p>
                                  Transitioned to freelancing. Specialized in
                                  assisting companies with securing tenders and
                                  bulk orders.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="toggle">
                          <div
                            className="toggle-title"
                            onClick={() => handleSetActiveTab("collapseSeven")}
                          >
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSeven"
                            >
                              Partnership with M/s. Bone Heal INC (current)
                            </a>
                          </div>
                          <div
                            className={`${
                              activeTab === "collapseSeven"
                                ? "show-content"
                                : "toggle-content"
                            }`}
                          >
                            <div className="row">
                              <div className="col-sm-9 text-justify">
                                <p>
                                  Elevated M/s. Keerthana Marketings to M/s.
                                  Bone Heal INC. Holds the exclusive Pan India
                                  distributorship for Gentacoll from Resorba
                                  GMBH Germany. Represents Austofix, an
                                  Australian company specializing in the
                                  manufacturing of various Plates, Screws, and
                                  Interlocking Nails. Recently signed an
                                  agreement to be the exclusive Pan India
                                  distributor for Synimed from France, a
                                  manufacturer of Antibiotic-coated Interlocking
                                  Nails, Spacers, and Bone Cements.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="toggle">
                          <div
                            className="toggle-title"
                            onClick={() => handleSetActiveTab("collapseEight")}
                          >
                            <a
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseEight"
                            >
                              Expansion and Partnerships
                            </a>
                          </div>
                          <div
                            className={`${
                              activeTab === "collapseEight"
                                ? "show-content"
                                : "toggle-content"
                            }`}
                          >
                            <div className="row">
                              <div className="col-sm-9 text-justify">
                                <p>
                                  Successfully established partnerships with
                                  potential Channel partners in several key
                                  Indian states, including Kerala, Tamil Nadu,
                                  Andhra Pradesh, Telangana, Maharashtra,
                                  Gujarat, Punjab, Rajasthan, New Delhi, Uttar
                                  Pradesh, Madhya Pradesh, and West Bengal.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="ttm-row broken-section bg-layer break-991-colum clearfix">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="ttm-col-bgcolor-yes ttm-bg ttm-left-span ttm-bgcolor-skincolor spacing-5">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>CLIENTS</h5>
                        <h2 className="title">Happy Patients & Clients</h2>
                      </div>
                      <div className="title-desc">
                        If you need any industrial solution we are available for
                        you. Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </div>
                    </div>
                    <a
                      className="ttm-btn ttm-btn-size-sm ttm-icon-btn-right ttm-btn-color-white btn-inline mt_25"
                      href="#"
                    >
                      VIEW MORE DETAILS<i className="ti ti-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="testimonial-box">
                  <div className="ttm-col-bgcolor-yes ttm-right-span ttm-bg ttm-bgcolor-darkgrey spacing-6">
                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                    <div className="layer-content">
                      <div
                        className="testimonial-slide owl-carousel"
                        data-item="1"
                        data-nav="true"
                        data-dots="false"
                        data-auto="true"
                      >
                        <div className="testimonials ttm-testimonial-box-view-style1">
                          <div className="testimonial-avatar">
                            <div className="testimonial-img">
                              <img
                                className="img-fluid"
                                src="images/testimonial/01.jpg"
                                alt="testimonial-img"
                              />
                            </div>
                            <div className="testimonial-caption">
                              <h5>RAshley Foster</h5>
                              <label>Patient</label>
                            </div>
                          </div>
                          <div className="testimonial-content">
                            <blockquote className="ttm-testimonial-text">
                              Prior to my stay at Delmont, I was aware of their
                              stellar reputation and multiple friends commented
                              about how lucky I was to have my surgery scheduled
                              there. As a result my expectations were high and
                              yet the kind and professional staff with excellent
                              patient care surpassed them.
                            </blockquote>
                            <div className="star-ratings">
                              <ul className="rating">
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="testimonials ttm-testimonial-box-view-style1">
                          <div className="testimonial-avatar">
                            <div className="testimonial-img">
                              <img
                                className="img-fluid"
                                src="images/testimonial/02.jpg"
                                alt="testimonial-img"
                              />
                            </div>
                            <div className="testimonial-caption">
                              <h5>Alan Sears</h5>
                              <label>Patient</label>
                            </div>
                          </div>
                          <div className="testimonial-content">
                            <blockquote className="ttm-testimonial-text">
                              Great co workers, really good environment and
                              excellent patient care. They are continuously
                              innovating themselves which is why they remain a
                              premier dental clinic.This was the cleanest
                              medical establishment I've ever been in. They just
                              loved the welcoming and warm atmosphere in there.
                            </blockquote>
                            <div className="star-ratings">
                              <ul className="rating">
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="testimonials ttm-testimonial-box-view-style1">
                          <div className="testimonial-avatar">
                            <div className="testimonial-img">
                              <img
                                className="img-fluid"
                                src="images/testimonial/03.jpg"
                                alt="testimonial-img"
                              />
                            </div>
                            <div className="testimonial-caption">
                              <h5>Raymon Myers</h5>
                              <label>Patient</label>
                            </div>
                          </div>
                          <div className="testimonial-content">
                            <blockquote className="ttm-testimonial-text">
                              The doctors and nurses and aides were excellent.
                              The food was excellent. I am feeling fine and very
                              lucky, back to my old self again and it feels
                              great. Thank God for clinics such as yours. I
                              appreciate all your kindness and good care you
                              gave me is beyond my expectations they wonder for
                              me.
                            </blockquote>
                            <div className="star-ratings">
                              <ul className="rating">
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <div className="ttm-row clientbox-section ttm-bgcolor-grey mt_70 res-991-mt-0 clearfix">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div
                  className="ttm-client clients-slide owl-carousel owl-theme owl-loaded mt-5"
                  data-item="5"
                  data-nav="false"
                  data-dots="false"
                  data-auto="false"
                >
                  <div className="client-box ttm-box-view-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-01"
                    >
                      <div className="client-thumbnail">
                        <img src="images/client/client-01.png" alt="image" />
                      </div>
                    </div>
                  </div>
                  <div className="client-box ttm-box-view-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-02"
                    >
                      <div className="client-thumbnail">
                        <img src="images/client/client-02.png" alt="image" />
                      </div>
                    </div>
                  </div>
                  <div className="client-box ttm-box-view-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-03"
                    >
                      <div className="client-thumbnail">
                        <img src="images/client/client-03.png" alt="image" />
                      </div>
                    </div>
                  </div>
                  <div className="client-box ttm-box-view-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-01"
                    >
                      <div className="client-thumbnail">
                        <img src="images/client/client-01.png" alt="image" />
                      </div>
                    </div>
                  </div>
                  <div className="client-box ttm-box-view-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-02"
                    >
                      <div className="client-thumbnail">
                        <img src="images/client/client-02.png" alt="image" />
                      </div>
                    </div>
                  </div>
                  <div className="client-box ttm-box-view-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-03"
                    >
                      <div className="client-thumbnail">
                        <img src="images/client/client-03.png" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <FooterSection />
      <a id="totop" href="#top">
        <i className="fa fa-angle-up"></i>
      </a>
    </div>
  );
}
