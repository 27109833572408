import React, { useState } from "react";
import NavBar from "../../widgets/NavBar";
import HeaderInfo from "../../widgets/HeaderInfo";
import FooterSection from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import { root } from "../../routes/routeUrls";
import emailjs from "@emailjs/browser";
// import FbarMain from "../../widgets/FbarMain";

interface SendMailRequest {
  from_name: string;
  to_name: string;
  from_email: string;
  phone_number: string;
  message: string;
}

const initialRequest = {
  from_name: "",
  to_name: "BoneHeal INC",
  from_email: "",
  phone_number: "",
  message: "",
};

export default function ContactUs(): React.ReactElement {
  const navigate = useNavigate();

  const [sendMailRequest, setSendMailRequest] = useState<SendMailRequest>({
    ...initialRequest,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSendMailRequest((sendMailRequest) => ({
      ...sendMailRequest,
      [name]: value,
    }));
    //setSendMailRequest
  };
  const handleOnTextAreaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSendMailRequest((sendMailRequest) => ({
      ...sendMailRequest,
      [name]: value,
    }));
    //setSendMailRequest
  };

  const handleSubmitClick = () => {
    emailjs
      .send(
        "boneheal-email-service",
        "template_9nxz1hq",
        {
          from_name: sendMailRequest.from_name,
          to_name: sendMailRequest.to_name,
          from_email: sendMailRequest.from_email,
          phone_number: sendMailRequest.phone_number,
          message: sendMailRequest.message,
        },
        "rImd18rfJ9G1ZTQOX"
      )
      .then(
        (result) => {
          setSendMailRequest({ ...initialRequest });
        },
        (error) => {
          setSendMailRequest({ ...initialRequest });
        }
      );
  };
  return (
    <div className="page">
      <header id="masthead" className="header ttm-header-style-classicinfo">
        {/* not sure what below html does */}
        {/* <FbarMain/> */}
        {/* <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
          <div className="container">
            <div className="ttm-topbar-content">
              <ul className="top-contact text-left">
                <li>
                  <i className="themifyicon ti-location-pin"></i>71/2, 7th 'A'
                  Cross Bazar Street, Rose Garden, Neelasandra, Bengaluru
                </li>
                <li>
                  <i className="themifyicon ti-timer"></i>Mon - Sat 10.00 AM -
                  06.30 PM Sunday CLOSED
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        <NavBar index={4} />
        <div className="ttm-content-wrapper">
          <div className="container">
            <HeaderInfo />
          </div>
        </div>
      </header>
      <div
        className="ttm-page-title-row"
        style={{ backgroundColor: "#033b4a" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title">Contact Us</h1>
                  <h3 className="subtitle">
                    {" "}
                    Reach out to us by call or drop in your requirements!
                  </h3>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(root)}
                          className="home"
                        >
                          <i className="themifyicon ti-home"></i>
                          &nbsp;&nbsp;Home
                        </span>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                      <span>Contact Us</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">
        <section className="ttm-row bg-layer bg-layer-equal-height res-991-p-0 clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="ttm-col-bgcolor-yes ttm-bg ttm-bgcolor-skincolor ttm-textcolor-white spacing-8">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <h3>
                      You may keep in touch with us for upto date information.
                    </h3>
                    <p>Contact Us for more details</p>
                    <div className="sep_holder_box width-100 mb-15">
                      <span className="sep_holder">
                        <span className="sep_line"></span>
                      </span>
                      <span className="sep_holder">
                        <span className="sep_line"></span>
                      </span>
                    </div>
                    <div className="featured-icon-box iconalign-before-heading mb-25">
                      <div className="featured-content">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                            <i className="fa fa-map-marker"></i>
                          </div>
                        </div>
                        <div className="featured-title">
                          <h5>
                            71/2, 7th 'A' Cross Bazar Street, Rose Garden,
                            Neelasandra.
                          </h5>
                          <h5>Bengaluru 560047</h5>
                        </div>
                      </div>
                    </div>
                    <div className="featured-icon-box iconalign-before-heading mb-25">
                      <div className="featured-content">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                            <i className="fa fa-envelope-o"></i>
                          </div>
                        </div>
                        <div className="featured-title">
                          <h5>boneheal@bonehealinc.com</h5>
                          <h4>Need support? Drop us an email</h4>
                        </div>
                      </div>
                    </div>
                    <div className="featured-icon-box iconalign-before-heading mb-25">
                      <div className="featured-content">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                            <i className="fa fa-phone"></i>
                          </div>
                        </div>
                        <div className="featured-title">
                          <h5>+91 7406533940</h5>
                          <h4>Have a question? call us now</h4>
                        </div>
                      </div>
                    </div>
                    <div className="featured-icon-box iconalign-before-heading mb-25">
                      <div className="featured-content">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                            <i className="fa fa-clock-o"></i>
                          </div>
                        </div>
                        <div className="featured-title">
                          <h5>Mon – Sat 10:00 AM – 06:30 PM</h5>
                          <h4>Our availability</h4>
                        </div>
                      </div>
                    </div>
                    <div className="sep_holder_box width-100 pt-20 mb-30">
                      <span className="sep_holder">
                        <span className="sep_line"></span>
                      </span>
                      <span className="sep_holder">
                        <span className="sep_line"></span>
                      </span>
                    </div>
                    {/* <div className="social-icons circle social-hover">
                      <ul className="list-inline">
                        <li className="social-facebook">
                          <a
                            className="tooltip-top"
                            target="_blank"
                            href="#"
                            data-tooltip="Facebook"
                          >
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li className="social-twitter">
                          <a
                            className="tooltip-top"
                            target="_blank"
                            href="#"
                            data-tooltip="Twitter"
                          >
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li className="social-gplus">
                          <a
                            className=" tooltip-top"
                            target="_blank"
                            href="#"
                            data-tooltip="Google+"
                          >
                            <i
                              className="fa fa-google-plus"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li className="social-linkedin">
                          <a
                            className=" tooltip-top"
                            target="_blank"
                            href="#"
                            data-tooltip="LinkedIn"
                          >
                            <i
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="ttm-col-bgcolor-yes ttm-bg ttm-bgcolor-grey spacing-8">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h5>WHAT WE DO</h5>
                      <h2 className="title">Get In Touch</h2>
                    </div>
                    <div className="title-desc">
                      We offer extensive medical & surgical operation theatre
                      equipments & accessories to outbound and inbound patients
                    </div>
                  </div>
                  <div
                    id="ttm-contactform"
                    className="ttm-contactform wrap-form clearfix"
                  >
                    <label>
                      <span className="text-input">
                        <i className="ttm-textcolor-skincolor ti-user"></i>
                        <input
                          name="from_name"
                          type="text"
                          value={sendMailRequest.from_name}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleOnChange(e)
                          }
                          placeholder="Your Name"
                          required={true}
                        />
                      </span>
                    </label>
                    <label>
                      <span className="text-input">
                        <i className="ttm-textcolor-skincolor ti-mobile"></i>
                        <input
                          name="phone_number"
                          type="text"
                          value={sendMailRequest.phone_number}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleOnChange(e)
                          }
                          placeholder="Mobile Number"
                          required={true}
                        />
                      </span>
                    </label>
                    <label>
                      <span className="text-input">
                        <i className="ttm-textcolor-skincolor ti-email"></i>
                        <input
                          name="from_email"
                          type="email"
                          value={sendMailRequest.from_email}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleOnChange(e)
                          }
                          placeholder="Email Address"
                          required={true}
                        />
                      </span>
                    </label>
                    <label>
                      <span className="text-input">
                        <i className="ttm-textcolor-skincolor ti-comment"></i>
                        <textarea
                          name="message"
                          cols={40}
                          rows={3}
                          value={sendMailRequest.message}
                          onChange={handleOnTextAreaChange}
                          placeholder="Requirement Details"
                          required={true}
                        ></textarea>
                      </span>
                    </label>
                    <input
                      name="submit"
                      type="submit"
                      id="submit"
                      onClick={handleSubmitClick}
                      className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-black"
                      value="SEND MESSAGE"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="map-wrapper">
          <div id="map_canvas"></div>
        </div> */}
      </div>
      <FooterSection />
      <a id="totop" href="#top">
        <i className="fa fa-angle-up"></i>
      </a>
    </div>
  );
}
