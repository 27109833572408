import Resorba from "../../assets/images/Events/Resorba.jpg";
import Austofix from "../../assets/images/Events/Austofix.jpg";
import Resorba01 from "../../assets/images/Events/Resorba01.jpeg";
import Resorba02 from "../../assets/images/Events/Resorba02.jpeg";
import Resorba03 from "../../assets/images/Events/Resorba03.jpeg";
import Resorba04 from "../../assets/images/Events/Resorba04.jpeg";
import Resorba05 from "../../assets/images/Events/Resorba05.jpeg";
import Resorba06 from "../../assets/images/Events/Resorba06.jpeg";
import Resorba07 from "../../assets/images/Events/Resorba07.jpeg";
import Resorba08 from "../../assets/images/Events/Resorba08.jpeg";
import Resorba09 from "../../assets/images/Events/Resorba09.jpeg";

export const events = [
  {
    id: 1,
    img: Resorba,
    eventDate: "1 Feb",
    eventName: "RESORBA Launch Event - 2023",
    eventDesc:
      "First time in India, gold standard antibiotic delivery system. A Unique product from the house of Advanced Medical Solutions RESORBA, Germany",
    eventPlace: "Bengaluru, Karnataka",
    isUpComing: false,
  },
  {
    id: 2,
    img: Austofix,
    eventDate: "Up Coming",
    eventName: "Austofix Launch Event - 2023",
    eventDesc:
      "This exclusive event will bring together healthcare professionals, industry experts for an immersive experience that showcases our commitment to excellence in patient care",
    eventPlace: "Bengaluru, Karnataka",
    isUpComing: true,
  },
];

export const eventGallery = [
  {
    id: 1,
    image: Resorba01,
    heading: "Hello",
    content: "This is Event one",
  },
  {
    id: 2,
    image: Resorba02,
    heading: "Hello",
    content: "This is Event one",
  },
  {
    id: 3,
    image: Resorba03,
    heading: "Hello",
    content: "This is Event Two",
  },
  {
    id: 4,
    image: Resorba04,
    heading: "Hello",
    content: "This is Event Three",
  },
  {
    id: 5,
    image: Resorba05,
    heading: "Hello",
    content: "This is Event Four",
  },
  {
    id: 6,
    image: Resorba06,
    heading: "Hello",
    content: "This is Event Five",
  },
  {
    id: 7,
    image: Resorba07,
    heading: "Hello",
    content: "This is Event Five",
  },
  {
    id: 8,
    image: Resorba08,
    heading: "Hello",
    content: "This is Event Five",
  },
  {
    id: 9,
    image: Resorba09,
    heading: "Hello",
    content: "This is Event Five",
  },
];
