import React from "react";

export default function FooterSection(): JSX.Element {
  return (
    <footer className="footer widget-footer clearfix">
      {/* <div className="first-footer ttm-bgcolor-skincolor">
    <div className="container">
      <div className="row">
        <div className="widget-area col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <aside className="widget widget-text">
            <div className="featured-icon-box iconalign-before-heading">
              <div className="featured-icon">
                <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                  <i className="fa fa-phone"></i>
                </div>
              </div>
              <div className="featured-title">
                <h5>+91 9632522721</h5>
                <h4>Have a question? call us now</h4>
              </div>
            </div>
          </aside>
        </div>
        <div className="widget-area col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <aside className="widget widget-text">
            <div className="featured-icon-box iconalign-before-heading">
              <div className="featured-icon">
                <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                  <i className="fa fa-envelope-o"></i>
                </div>
              </div>
              <div className="featured-title">
                <h5>poorvikaot2015@gmail.com</h5>
                <h4>Need support? Drop us an email</h4>
              </div>
            </div>
          </aside>
        </div>
        <div className="widget-area col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <aside className="widget widget-text">
            <div className="featured-icon-box iconalign-before-heading">
              <div className="featured-icon">
                <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                  <i className="fa fa-clock-o"></i>
                </div>
              </div>
              <div className="featured-title">
                <h5>Mon – Sat 10:00 – 06:30</h5>
                <h4>We are open on</h4>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </div> */}
      <div className="bottom-footer-text ttm-textcolor-white">
        <div className="container">
          <div className="row copyright">
            <div className="col-md-12">
              <span>
                Copyright © 2023 Designed & Developed by{" "}
                <a href="#">Acentrio Solutions</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
