import React from "react";
import SliderIcon1 from "../../assets/images/slides/slider-icon001.png";
import NavBar from "../../widgets/NavBar";
import HeaderInfo from "../../widgets/HeaderInfo";
import FooterSection from "../../components/Footer";
// import FbarMain from "../../widgets/FbarMain";

export default function HomePage(): React.ReactElement {
  return (
    <div className="page">
      <header id="masthead" className="header ttm-header-style-classicinfo">
        {/* not sure what below html does */}
        {/* <FbarMain/> */}
        {/* <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
          <div className="container">
            <div className="ttm-topbar-content">
              <ul className="top-contact text-left">
                <li>
                  <i className="themifyicon ti-location-pin"></i>71/2, 7th 'A'
                  Cross Bazar Street, Rose Garden, Neelasandra, Bengaluru
                </li>
                <li>
                  <i className="themifyicon ti-timer"></i>Mon - Sat 10.00 AM -
                  06.30 PM Sunday CLOSED
                </li>
              </ul>
            </div>
          </div>
        </div> */}

        <NavBar index={0} />

        <div className="ttm-content-wrapper">
          <div className="container">
            <HeaderInfo />
          </div>
        </div>
      </header>

      <div className="hero">
        <div className="content">
          <div className="icon-text">
            <span>We Give You The Best!</span>
          </div>
          <span>
            <span className="ttm-textcolor-skincolor">Medical</span> Service
          </span>
          <span>
            That You Can<span className="ttm-textcolor-skincolor">Trust</span>
          </span>
          <span className="subtext">
            Need professional help? Our support staff will answer your
            questions.
            <br />
            Visit us Now or Drop in your requirements!
          </span>
          <span>
            <a href="#" className="reachus-btn">
              Reach Us Now!
            </a>
          </span>
        </div>
      </div>

      <div className="site-main">
        {/* <section className="ttm-row row-top-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mt_45 res-991-mt-50 ttm-bgcolor-white">
                  <div className="row no-gutters">
                    <div className="col-lg">
                      <div className="featured-icon-box top-icon style4 text-center">
                        <div className="featured-icon-box-inner">
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon-care"></i>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5>Oral Care</h5>
                            </div>
                            <div className="featured-desc">
                              <p>The Stronger Teeth</p>
                            </div>
                            <div className="ttm-di_links">
                              <a href="#" className="di_link">
                                <i className="fa fa-angle-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="featured-icon-box top-icon style4 text-center">
                        <div className="featured-icon-box-inner">
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon-heart-3"></i>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5>Heal Body</h5>
                            </div>
                            <div className="featured-desc">
                              <p>A small carebear</p>
                            </div>
                            <div className="ttm-di_links">
                              <a href="#" className="di_link">
                                <i className="fa fa-angle-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="featured-icon-box top-icon style4 text-center">
                        <div className="featured-icon-box-inner">
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon-ambulance"></i>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5>Ambulance</h5>
                            </div>
                            <div className="featured-desc">
                              <p>Shipping Via Air</p>
                            </div>
                            <div className="ttm-di_links">
                              <a href="#" className="di_link">
                                <i className="fa fa-angle-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="featured-icon-box top-icon style4 text-center">
                        <div className="featured-icon-box-inner">
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon-medicines"></i>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5>Drug store</h5>
                            </div>
                            <div className="featured-desc">
                              <p>A retailer shop</p>
                            </div>
                            <div className="ttm-di_links">
                              <a href="#" className="di_link">
                                <i className="fa fa-angle-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="featured-icon-box top-icon style4 text-center">
                        <div className="featured-icon-box-inner">
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon-eye"></i>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5>Eye Care</h5>
                            </div>
                            <div className="featured-desc">
                              <p>Sharping vision</p>
                            </div>
                            <div className="ttm-di_links">
                              <a href="#" className="di_link">
                                <i className="fa fa-angle-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="ttm-row broken-section bg-img6 bg-layer bg-layer-equal-height clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="ttm-fid-border ttm-col-bgcolor-yes ttm-bg ttm-right-span ttm-bgcolor-skincolor spacing-1">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <div className="section-title clearfix">
                      <div className="title-header">
                        <h5>
                          BoneHeal is an authorized distributor of high-quality
                          German/Australian instruments
                        </h5>
                        <h2 className="title">
                          Powered By Over 100+ Clients.
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="ttm-row services-section ttm-bgcolor-darkgrey bg-img5 ttm-bg ttm-bgimage-yes ttm-bgcolor-darkgrey clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>WE OFFER SERVICES</h5>
                    <h2 className="title">Special High-quality Services</h2>
                  </div>
                  <div className="title-desc">
                    We bring 20 years of experience in orthopedic surgical
                    instruments to your customer service experience, so you can
                    count on us to solve instrument problems or hard-to-find
                    pattern requests quickly and efficiently. Your calls will be
                    answered by knowledgeable professionals dedicated to
                    providing the highest levels of service.Quality and trust
                    are two major factors that an Orthopedic surgeon takes into
                    consideration while selecting orthopedic implants.
                    Orthopedic experts all over the world not only trust NET
                    Orthopedic Implants but also recommends them to fellow
                    professionals with confidence because of their high quality.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="ttm-row cta-section bg-img1 clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="pt-70 pb-140 mt-50 row-title res-991-mt-0 res-991-pb-30 text-center">
                  <div className="ttm-video-btn">
                    <div className="ttm-play-btn ttm_prettyphoto">
                      <span className="ttm-btn-play">
                        <i className="fa fa-play"></i>
                      </span>
                    </div>
                  </div>

                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h5>Time can't be Resisted, But Aging Can</h5>
                      <h2 className="title">
                        Commited To Trusted Health Care Equipments
                      </h2>
                    </div>
                  </div>
                  <h4>
                    Get Your Quote or Call:
                    <span className="ttm-textcolor-skincolor"> </span>{" "}
                  </h4>
                  <div className="mt-40 res-991-mt-30">
                    <a
                      className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor mb-20"
                      href="#"
                    >
                      Reach Us Now!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="ttm-row broken-section break-991-colum bg-layer clearfix">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="ttm-col-bgcolor-yes ttm-bg ttm-left-span ttm-bgcolor-skincolor spacing-2">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>CLIENTS</h5>
                        <h2 className="title">Happy Patients & Clients</h2>
                      </div>
                      <div className="title-desc">
                        If you need any medical help we are available for you.
                        Lorem ipsum dolor sit amet, sectetur adipiscing elit,
                        sed do eiusmod tempor the incididunt ut labore et
                        dolore.
                      </div>
                    </div>
                    <a
                      className="ttm-btn ttm-btn-size-sm ttm-icon-btn-right ttm-btn-color-white btn-inline mt_25"
                      href="#"
                    >
                      VIEW MORE DETAILS<i className="ti ti-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="ttm-col-bgcolor-yes ttm-right-span ttm-bg ttm-bgcolor-darkgrey spacing-3">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div className="layer-content">
                    <div
                      className="testimonial-slide owl-carousel owl-theme owl-loaded"
                      data-item="1"
                      data-nav="true"
                      data-dots="false"
                      data-auto="false"
                    >
                      <div className="testimonials ttm-testimonial-box-view-style1">
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">
                            <img
                              className="img-fluid"
                              src={Testimonial1}
                              alt="testimonial-img"
                            />
                          </div>
                          <div className="testimonial-caption">
                            <h5>RAshley Foster</h5>
                            <label>Patient</label>
                          </div>
                        </div>
                        <div className="testimonial-content">
                          <blockquote className="ttm-testimonial-text">
                            Prior to my stay at Delmont, I was aware of their
                            stellar reputation and multiple friends commented
                            about how lucky I was to have my surgery scheduled
                            there. As a result my expectations were high and yet
                            the kind and professional staff with excellent
                            patient care surpassed them.
                          </blockquote>
                          <div className="testimonial-footer">
                            <div className="star-ratings">
                              <ul className="rating">
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li>
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                            </div>
                            <div className="testimonial-control">
                              <i
                                className="fa fa-hand-o-left"
                                aria-hidden="true"
                              ></i>
                              <i
                                className="fa fa-hand-o-right"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <div className="ttm-row client-section ttm-bgcolor-grey mt_70 res-991-mt-0 clearfix">
          <div className="container m-18">
            <div className="row text-center">
              <div className="col-md-12">
                <div
                  className="clients"
                  ata-item="5"
                  data-nav="false"
                  data-dots="false"
                  data-auto="false"
                >
                  <div className="client-box ttm-box-view-separator-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-01"
                    >
                      <div className="client-thumbnail">
                        <img
                          width="116"
                          height="81"
                          src={Client1}
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="client-box ttm-box-view-separator-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-02"
                    >
                      <div className="client-thumbnail">
                        <img
                          width="116"
                          height="81"
                          src={Client2}
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="client-box ttm-box-view-separator-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-03"
                    >
                      <div className="client-thumbnail">
                        <img
                          width="116"
                          height="81"
                          src={Client3}
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="client-box ttm-box-view-separator-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-04"
                    >
                      <div className="client-thumbnail">
                        <img
                          width="116"
                          height="81"
                          src={Client1}
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="client-box ttm-box-view-separator-logo">
                    <div
                      className="ttm-client-logo-tooltip"
                      data-tooltip="client-05"
                    >
                      <div className="client-thumbnail">
                        <img
                          width="116"
                          height="81"
                          src={Client1}
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <FooterSection />

      <a id="totop" href="#top">
        <i className="fa fa-angle-up"></i>
      </a>
    </div>
  );
}
