import React from "react";

export default function GalleryItem(props: { item: any }): React.ReactElement {
  return (
    <div className="ttm-box-col-wrapper ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
      <div className="featured-imagebox featured-imagebox-portfolio ttm-box-view-top-image ttm-box-view-top-image">
        <div className="ttm-box-view-content-inner">
          <div className="featured-thumbnail">
            <a href="#">
              {" "}
              <img className="img-fluid-1" src={props.item.image} height={240} alt="image" />
            </a>
          </div>
        </div>
        {/* <div className="ttm-box-bottom-content featured-content-portfolio box-shadow">
          <div className="featured-title">
            <h5>{props.item.heading}</h5>
          </div>
          <span className="category">{props.item.content}</span>
        </div> */}
      </div>
    </div>
  );
}
