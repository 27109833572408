import * as routepaths from "./routeUrls";
import HomePage from "../screens/HomePage";
import About from "../screens/AboutUs";
import Product from "../screens/Products";
import ContactUs from "../screens/ContactUs";
import Events from "../screens/Events";

export interface IRoutePaths {
  path: string;
  component: React.ElementType;
}

export const routesPaths: IRoutePaths[] = [
  {
    path: routepaths.root,
    component: HomePage,
  },
  {
    path: routepaths.about,
    component: About,
  },
  {
    path: routepaths.products,
    component: Product,
  },
  {
    path: routepaths.contactUs,
    component: ContactUs,
  },
  {
    path: routepaths.events,
    component: Events,
  },
];
