import React, { useState } from "react";
import FooterSection from "../../components/Footer";
import NavBar from "../../widgets/NavBar";
import EventItem from "../../widgets/EventItem";
import { eventGallery, events } from "./eventData";
import GalleryItem from "../../widgets/GalleryItem";
import HeaderInfo from "../../widgets/HeaderInfo";
import { useNavigate } from "react-router-dom";
import { root } from "../../routes/routeUrls";

export default function Events(): React.ReactElement {
  const navigate = useNavigate()
  const [showEventDetails, setShowEventDetails] = useState(false);

  const handleToggle = () => setShowEventDetails(!showEventDetails);
  return (
    <div className="page">
      <header id="masthead" className="header ttm-header-style-classicinfo">
        {/* not sure what below html does */}
        {/* <FbarMain/> */}

        {/* <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
          <div className="container">
            <div className="ttm-topbar-content">
              <ul className="top-contact text-left">
                <li>
                  <i className="themifyicon ti-location-pin"></i>71/2, 7th 'A'
                  Cross Bazar Street, Rose Garden, Neelasandra, Bengaluru
                </li>
                <li>
                  <i className="themifyicon ti-timer"></i>Mon - Sat 10.00 AM -
                  06.30 PM Sunday CLOSED
                </li>
              </ul>
            </div>
          </div>
        </div> */}

        <NavBar index={3} />
        <div className="ttm-content-wrapper">
          <div className="container">
            <HeaderInfo />
          </div>
        </div>
      </header>

      <div
        className="ttm-page-title-row"
        style={{ backgroundColor: "#033b4a" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title">Our Events</h1>
                  <h3 className="subtitle">
                  </h3>
                </div>
                <div className="breadcrumb-wrapper">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={()=>navigate(root)}
                          className="home"
                        >
                          <i className="themifyicon ti-home"></i>
                          &nbsp;&nbsp;Home
                        </span>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                      <span>Events</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row-1 pb-60 res-991-pb-20 clearfix">
          {showEventDetails ? (
            <div className="row multi-columns-row ttm-boxes-row-wrapper pt-15">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 content-area">
                    <div
                      className="breadcrumb-wrapper pointer-cursor"
                      style={{ padding: "12px 0" }}
                      onClick={() => handleToggle()}
                    >
                      <span>
                        <span className="home" style={{ fontSize: "16px" }}>
                          <i className="ti-control-backward"></i>
                          &nbsp;&nbsp;Back to Events
                        </span>
                      </span>
                    </div>
                    <div className="products row">
                      {eventGallery.map((events) => (
                        <GalleryItem item={events} />
                      ))}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="row multi-columns-row ttm-boxes-row-wrapper">
                {events.map((event) => (
                  <EventItem event={event} handleToggle={handleToggle} />
                ))}
              </div>
            </div>
          )}
        </section>
      </div>
      <br />
      <FooterSection />
      <a id="totop" href="#top">
        <i className="fa fa-angle-up"></i>
      </a>
    </div>
  );
}
