import React from "react";

export default function EventItem(props: {
  event: any;
  handleToggle: Function;
}): React.ReactElement {
  return (
    <div className="ttm-box-col-wrapper col-lg-4 col-sm-6 col-md-4 col-xs-12">
      <div className="featured-imagebox featured-imagebox-event ttm-box-view-top-image">
        <div className="featured-thumbnail">
          <img className="img-fluid" src={props.event.img} alt="" />
        </div>
        <div className="featured-content featured-content-bottom">
          <div className="ttm-events-meta">
            <div
              className="ttm-meta-date "
              style={{
                backgroundColor: props.event.isUpComing
                  ? "#dc3545fa"
                  : "#01d6a3",
              }}
            >
              <span className="ttm-event-date">
                <span className="ttm-event-meta-dtstart">
                  {props.event.eventDate.split(" ")[0]}
                  <span className="entry-month">
                    {props.event.eventDate.split(" ")[1]}
                  </span>
                </span>
                <span className="ttm-event-meta-dtend"> </span>
              </span>
            </div>
          </div>
          <div className="featured-title">
            <h5>
              <span>{props.event.eventName}</span>
            </h5>
          </div>
          <div className="featured-desc">
            <p>{props.event.eventDesc}</p>
          </div>
          <div className="tribe-events-vanue">
            {" "}
            <i className="fa fa-map-marker"></i>
            {props.event.eventPlace}
          </div>
          <div className="ttm-eventbox-footer">
            {props.event.isUpComing ? (
              <span className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor ttm-icon-btn-right btn-inline">
                Up Coming...
              </span>
            ) : (
              <span
                className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor ttm-icon-btn-right btn-inline pointer-cursor"
                onClick={() => props.handleToggle()}
              >
                View Details<i className="ti ti-arrow-right"></i>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
