import React from "react";
import Product5 from "../../assets/images/product/product_01.png";

interface productProps {
  product: any;
  handleToggle: Function;
}

export default function ProductItem({
  product,
  handleToggle,
}: productProps): React.ReactElement {
  return (
    <div
      className="product col-md-3 col-sm-6 col-xs-12 pointer-cursor"
      style={{ margin: "1% 0" }}
      onClick={() => handleToggle(product.id)}
    >
      <div className="ttm-product-box">
        <div className="ttm-product-box-inner">
          <div className="ttm-product-image-box">
            <img className="img-fluid-2" src={product.image} alt="" />
          </div>
        </div>
        <div className="ttm-product-content">
          <span
            className="ttm-product-title pointer-cursor"
          >
            <h2>{product.heading}</h2>
          </span>
        </div>
      </div>
    </div>
  );
}
