export const headerWidgetContent = [
  {
    header: "Nature of Business",
    para: "Distributor",
    icon: "fa fa-hospital-o",
  },
  {
    header: "GST Number",
    para: "29AAJFK0118Q1ZX",
    icon: "fa fa-user-md",
  },
  {
    header: "Year of Establishment",
    para: "2022",
    icon: "fa fa-thumbs-o-up",
  },
];
