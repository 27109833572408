import React from "react";
import HeaderWidget from "../../components/HeaderWidget";
import { headerWidgetContent } from "../../utils/contents";
export default function HeaderInfo(): React.ReactElement {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="ttm-info-widget">
          {headerWidgetContent.map((content) => (
            <HeaderWidget
              key={content.header}
              header={content.header}
              para={content.para}
              icon={content.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
