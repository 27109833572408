import Product_01 from "../../assets/images/product/Genta-coll.png";

export const productList = [
  {
    id: 1,
    image: Product_01,
    heading: "GENTA-COLL resorb",
    content: "This is product one",
    introduction: "Collagen, sourced from bones and skins, was used as an adhesive for woodwork and wallpapering since more than 6000 years. First medical use for hemostasis and wound treatment about 3000 years ago. Root word is kolla (Greek): adhesive-producing - means glue that is why collagen sticks to wet surfaces, blood and tissues. Collagens are a group of structural proteins (a protein forming a fibre bundle) occurring only in multicellular animals / highly developed (including humans), mainly in the connective tissue(more precisely: the extracellular matrix). Collagens are found, among other things, in the white, inelastic fibres of tendons,ligaments, bones, periodontium, subcutis and cartilage.",
    description: `Collagen is the most abundant protein in the human body.
    It accounts for 6% of the total body weight of the human body, i.e.
    3.6 kg in a 60 kg person, and about a quarter of the total protein.
    Collagens are insoluble in water and can only be broken down 
    enzymatically - via collagenases
    Collagen is a very conserved molecule, in all highly developed 
    animals, helical domains consist of amino acids and hydrogen: 
    Glycin, Alanin, Prolin, Hydroxyprolin & Hydroxylysin nothing else.`,
    category: "Medical Devices",
    dimension: "2.5cm X 2.5cm and 5cm X 5cm",
    pieces : "5 Sponges",
    color: "White",
    catalogPath : "../../assets/ProductCatalogs/Genta-Coll.pdf"
  },
];
