import React, { useState } from "react";

export default function SingleProduct(props: {
  product: any;
}): React.ReactElement {
  const handleDownload = () => {
    const pdfUrl = process.env.PUBLIC_URL + '/GentaColl.pdf';
    const anchor = document.createElement('a');
    anchor.href = pdfUrl;
    anchor.download = 'GentaColl.pdf'; 
    anchor.click();
  };
  const [activeTab, setActiveTab] = useState("desc");
  return (
    <div className="col-lg-12 content-area">
      <div className="ttm-single-product-details product">
        <div className="ttm-single-product-info clearfix">
          <div className="product-gallery images">
            <figure className="ttm-product-gallery__wrapper">
              <div className="product-gallery__image">
                <img
                  className="img-fluid"
                  src={props.product.image}
                  alt="product-img"
                />
              </div>
            </figure>
          </div>
          <div className="summary entry-summary">
            <h1 className="product_title entry-title">{props.product.heading}</h1>
            <div className="product-details__short-description text-justify">
              <p>
               {props.product.introduction}
              </p>
            </div>
            {/* <div className="add-to-wishlist yith-wcwl-add-to-wishlist">
              <div className="product_meta">
                <span className="posted_in">
                  Category:
                  <a href="#" rel="tag">
                  {props.product.category}
                  </a>
                </span>
              </div>
            </div> */}
          </div>
        </div>
        <div className="ttm-tabs tabs-for-single-products" data-effect="fadeIn">
          <ul className="tabs clearfix">
            <li
              className={`tab ${activeTab === "desc" ? "active" : ""}`}
              onClick={() => setActiveTab("desc")}
            >
              <a href="#">Description</a>
            </li>
            <li
              className={`tab ${activeTab === "prod" ? "active" : ""}`}
              onClick={() => setActiveTab("prod")}
            >
              <a href="#">Product Details</a>
            </li>
          </ul>
          <div className="content-tab ttm-bgcolor-white">
            {activeTab === "desc" ? (
              <div className="content-inner text-justify">
                <h2>Description</h2>
                <p>
                {props.product.description}
                </p>
              </div>
            ) : (
              <div className="content-inner">
                <h2>Additional information</h2>
                <table className="shop_attributes">
                  <tbody>
                    <tr>
                      <th>Dimensions</th>
                      <td className="product_dimensions">{props.product.dimension}</td>
                    </tr>
                    <tr>
                      <th>Pieces per/Box</th>
                      <td>
                        <p>{props.product.pieces}</p>
                      </td>
                    </tr>
                    <tr>
                      <th>color</th>
                      <td>
                        <p>{props.product.color}</p>
                      </td>
                    </tr>
                    <tr>
                      <th>Catalog</th>
                      <td>
                      <a style={{ cursor: "pointer", color:"blue", textDecoration:"underline" }} onClick={handleDownload}>Download Catalog</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
