import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as routepaths from "../../routes/routeUrls";
import Logo from "../../assets/images/logo-img.png";
import "../../App.css";

export default function NavBar(props: { index: Number }): React.ReactElement {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const navItems = [
    {
      name: "Home",
      link: routepaths.root,
    },
    {
      name: "About Us",
      link: routepaths.about,
    },
    {
      name: "Products",
      link: routepaths.products,
    },
    {
      name: "Events",
      link: routepaths.events,
    },
    {
      name: "Contact Us",
      link: routepaths.contactUs,
    },
  ];

  const showNavDropDown = useCallback(() => {
    setIsActive(!isActive);
  }, []);

  const handleOnClick = (navItem: any) => {
    setIsActive(false);
    navigate(navItem.link);
  };

  return (
    <div className="ttm-header-wrap">
      <div
        id="ttm-stickable-header-w"
        className="ttm-stickable-header-w ttm-bgcolor-white clearfix"
      >
        <div id="site-header-menu" className="site-header-menu">
          <div className="site-header-menu-inner ttm-stickable-header">
            <div className="container">
              <div className="site-branding">
                <span className="home-link" onClick={()=>navigate(routepaths.root)}>
                  <span className="nav-logo">
                    <span className="ttm-textcolor-skincolor">BoneHeal</span>{" "}
                    INC
                  </span>
                </span>
              </div>

              <div id="site-navigation" className="site-navigation">
                <div className="ttm-header-icons ">
                  <div className="ttm-header-icon ttm-header-search-link">
                    <a href="#">
                      <i className="ti ti-search"></i>
                    </a>
                    <div className="ttm-search-overlay">
                      <div className="ttm-search-outer">
                        <div className="ttm-form-title">
                          Hi, How Can We Help You?
                        </div>
                        <form
                          method="get"
                          className="ttm-site-searchform"
                          action="#"
                        >
                          <div className="w-search-form-h">
                            <div className="w-search-form-row">
                              <div className="w-search-input">
                                <input
                                  onChange={() => {
                                    console.log("hi");
                                  }}
                                  type="search"
                                  className="field searchform-s"
                                  name="s"
                                  placeholder="Type Word Then Enter..."
                                />
                                <button type="submit">
                                  <i className="ti ti-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="ttm-menu-toggle"
                  onClick={() => showNavDropDown()}
                >
                  <label
                    htmlFor="menu-toggle-form"
                    className="ttm-menu-toggle-block"
                  >
                    <span className="toggle-block toggle-blocks-1"></span>
                    <span className="toggle-block toggle-blocks-2"></span>
                    <span className="toggle-block toggle-blocks-3"></span>
                  </label>
                </div>
                <nav id="menu" className={`menu ${isActive ? "active" : ""}`}>
                  <ul className="dropdown">
                    {navItems.map((navItem, i) => (
                      <li key={navItem.name}>
                        <span
                          className={`nav-items ${
                            i === props.index ? "nav-active" : ""
                          }`}
                          onClick={() => handleOnClick(navItem)}
                        >
                          {navItem.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
